@import '../../assets/css/variables.scss';

.btn {
    background-color: #242222 !important;
    height: 48px;
    width: max-content !important;

    div {
        color: #FFFFFF
    }
}

.headerWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 16px;

    @media screen and (max-width: $medium-screen) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        gap: 16px;
    }
}

.searchWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}

.clientBtnWrapper {
    display: flex;
    gap: 8px;
    width: 100%;
}