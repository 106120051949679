body {
  background: lightgray;
  margin: 0;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}
