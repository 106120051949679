.rowSelector {
    width: 100% !important;
    height: 40px !important;
}

.cardSections {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.section {
    display: flex;
    gap: 8px;
    align-items: flex-end;
}

.fileUpload {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
}

.removeBtn, .addBtn {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    padding: 0 0 3px 0;
    margin-left: auto;
}

.removeBtn {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-bottom: 10px;
}

.addBtn {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    background-color: #242222;
    div {
        color: #FFFFFF;
    }
}

.btn {
    background-color: #242222 !important;
    height: 36px;
    padding: 6px 12px;
    width: max-content !important;
    min-width: 70px;
    justify-content: center;

    div {
        font-size: 14px;
        color: #FFFFFF;
    }
}

.btnContainer, .btnClientEntry {
    display: flex;
    gap: 16px;
}

.btnClientEntry {
    justify-content: flex-end;
}

.header {
    display: flex;
    justify-content: space-between;
}

.headerTxt {
    font-size: 20px;
    font-weight: 700;
    color: #1F1F1F;
}

.icon {
    cursor: pointer;
}