@import '../../assets/css/variables.scss';

.container {
    display: flex;
    width: auto;
    padding: 12px 24px 12px 24px;
    gap: 8px;
    border-radius: 6px;
    background-color: #FFFFFF;
    border: 1px solid #242222;
    box-sizing: border-box;
    align-self: flex-end;
    cursor: pointer;
}

.btn {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-family: $font-family-lato;
    font-size: 16px;
    font-weight: 600;
    color: #242222;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}