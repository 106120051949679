.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  height: 100vh;
  font-family: "Roboto","Helvetica","Arial",sans-serif;

  .label {
    margin-top: -20vh;
    margin-bottom: 1.5rem;
    font-size: 28px;
    font-weight: 700;
  }

  .login {
    font-size: 18px;
  }
}