.btn {
    background-color: #242222 !important;
    height: 48px;
    width: max-content !important;
    min-width: 95px;
    justify-content: center;

    div {
        color: #FFFFFF;
    }
}

.header {
    display: flex;
    justify-content: space-between;
}

.headerTxt {
    font-size: 20px;
    font-weight: 700;
    color: #1F1F1F;
}

.txt {
    font-size: 16px;
    font-weight: 500;
    color: #1F1F1F;
}

.icon {
    cursor: pointer;
}

.btnClientEntry {
    display: flex;
    justify-content: center;
    gap: 16px;
}