.container, .containerWithSubmit {
    display: flex;
    position: relative;
    width: -webkit-fill-available;
    gap: 4px;
    color: #242222
}

.container {
    flex-direction: column;
    align-items: flex-start;
}

.containerWithSubmit {
    align-items: center;
}

.input {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgb(230, 230, 230);
    height: 40px;
}

.error, .success {
    position: absolute;
    font-size: 13px;
    color: #ff0000;
    bottom: -16px;
    text-wrap: nowrap;
}

.error {
    color: #ff0000;
}

.success {
    color: #059669;
}

.btn {
    background-color: #242222 !important;
    height: 48px;
    width: max-content !important;

    div {
        color: #FFFFFF
    }
}